import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import Home from "../components/layouts/home";
import SEO from "../components/seo/seo";
import HeroChevron from "../components/hero/hero-chevron";
import PersonalizationCards from "../components/card/personalization-cards";
import LargeCards from "../components/card/large-cards";
import ServiceStates from "../components/service-states/service-states";
import QuickLinks from "../components/quick-links/quick-links";
import BestBanksDefault from "../components/best-banks/best-banks-default";

// Helpers
import getHeroImgVariables from "../helpers/getHeroImgVariables";

const IndexPage = () => {
  // NOTE: the new chevron hero image file naming convention below uses the device size rather that the image size.
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/homepage/hero-heloc-rate-discount-082224-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "home-page-hero",
    ...getHeroImgVariables(pageData),
    altText: "Couple looking at blueprints with contractor while kids play.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Get a 1% Discount on Your HELOC Rate"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Apply Now",
            url: "/personal-banking/home-loans/heloc-home-equity-line-of-credit",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white hero-tertiary-text",
            text: "Terms and conditions apply, subject to change and credit approval."
          }
        }
      ]
    }
  };

  const SEOData = {
    title: "WaFd Bank: Bank Accounts, Home Loans, and Commercial Financing",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "WaFd Bank: Bank Accounts, Home Loans, and Commercial Financing"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Washington Federal, now known as WaFd Bank, offers  services including checking and savings accounts, mortgages, HELOC, construction and lot loans."
      },
      {
        name: "google-site-verification",
        content: "_mFadtR6nMvgpFsiuNrksFw-FqJ9tQ1MMSNbp6-8XtE"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/homepage/og-heloc-rate-discount-082224.jpg"
      }
    ],
    schema: {}, // TODO: Still missing the schema
    addSuffix: false
  };

  return (
    <Home footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <QuickLinks />
      <section className="bg-info">
        <PersonalizationCards containerClass="bg-info" />
        <LargeCards containerClass="bg-info" />
        <ServiceStates containerClass="bg-info" />
      </section>
      <BestBanksDefault />
    </Home>
  );
};

export default IndexPage;
